import { NestedLinks } from '@emotioncod/cm-design-system';

interface RouteData extends NestedLinks {
  id: string;
  route: string;
}

const navRoutes: RouteData[] = [
  {
    text: 'Companies',
    icon: 'mdiDomain',
    id: 'companies-page',
    route: '/companies'
  },
  {
    text: 'Job positions',
    icon: 'mdiBriefcaseAccount',
    id: 'job-positions-page',
    route: '/job-positions'
  },
  {
    text: 'Candidates',
    icon: 'mdiAccountGroup',
    id: 'candidates-page',
    route: '/candidates'
  },
  {
    text: 'Matches',
    icon: 'mdiBriefcaseEye',
    id: 'matches-page',
    route: '/matches'
  }
];

export default navRoutes;
