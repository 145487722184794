<script setup lang="ts">
import { CmContainer, CmIcon, CmInput } from '@emotioncod/cm-design-system-vue';
import { EventType } from '@emotioncod/cm-definitions/lib/Matches/const';
import type { CandidateNotesPanel } from '~/types/bo-types';
import { mapListNotes, mapListNotesToMatch } from '~/resolvers/candidate';
import { resolveJobPositionName } from '~/resolvers/jobPositions';

const props = defineProps<{
  candidateId: string;
  matchId: string;
  notes: CandidateNotesPanel;
  visible: boolean;
  side: 'left' | 'right';
  hideBackdrop?: boolean;
  disableCloseButton?: boolean;
}>();

const emit = defineEmits<{
  (e: 'close-note'): void;
}>();

const note = ref<string>('');
const notes = ref<CandidateNotesPanel>(props.notes);

const matchesStore = useMatches();
const candidateStore = useCandidates();

watch(
  () => props.notes,
  () => {
    notes.value = props.notes;
  }
);

const saveNoteToMatch = async (matchId: string, note: string) => {
  const currentDate = new Date();

  const match = await matchesStore.postEventMatch(matchId, {
    content: note,
    date: currentDate.toISOString(),
    source: 'Talent partner',
    type: EventType.NOTE
  });

  if (match) {
    notes.value = {
      fullName: match.candidate?.name + ' ' + match.candidate?.surname,
      list: mapListNotesToMatch(match.events, resolveJobPositionName(matchId))
    };
  }
};

const saveNoteToCandidate = async (candidateId: string, note: string) => {
  const currentDate = new Date();

  const comment = await candidateStore.postComment(candidateId, {
    content: note,
    date: currentDate.toISOString(),
    source: 'Talent partner',
    type: EventType.NOTE_ADDED
  });

  if (comment) {
    notes.value = {
      ...notes.value,
      list: notes.value.list.concat(mapListNotes([comment]))
    };
  }
};

const saveNoteHandler = async () => {
  if (props.matchId) {
    await saveNoteToMatch(props.matchId, note.value);
  } else {
    await saveNoteToCandidate(props.candidateId, note.value);
  }

  note.value = '';
};

function closeBackdrop(e: MouseEvent) {
  if (e.target === e.currentTarget) {
    emit('close-note');
  }
}

/**
 * Calculate the number of rows for the textarea
 * @returns number of rows
 * @see TAL-533
 */
function getRows() {
  const indexLenght = note.value.length / 35;

  if (Math.round(indexLenght) < 1) return 1;

  if (Math.round(indexLenght) > 5) return 5;

  return Math.round(indexLenght);
}
</script>

<template>
  <div
    :class="{
      'cm-modal-wrapper': true,
      visible: props.visible,
      'hide-backdrop': props.hideBackdrop
    }"
    role="dialog"
    :aria-modal="props.visible"
    :aria-hidden="props.visible"
    tabindex="0"
    @click="closeBackdrop"
  >
    <div
      :class="`cm-modal-base
      side-panel-container
      side-panel-${props.side}`"
      role="navigation"
    >
      <div class="cm-side-panel-head">
        <div class="cm-b2 font-semibold">Notes to {{ notes?.fullName }}</div>
        <cm-icon
          v-if="!props.disableCloseButton"
          icon-name="mdiClose"
          color="grey-70"
          size="24"
          aria-label="Close"
          tabindex="0"
          @click="emit('close-note')"
        ></cm-icon>
      </div>
      <div class="container-notes">
        <div v-if="notes.list.length > 0" class="cm-side-panel-content-notes">
          <div
            v-for="n in notes.list
              .filter(
                (el) =>
                  el.typeEvent.toLowerCase() === EventType.NOTE.toLowerCase() ||
                  el.typeEvent.toLowerCase() ===
                    EventType.NOTE_ADDED.toLowerCase()
              )
              .filter((el) => !el?.deleted_at)"
            :key="n.id"
            class="text-secondary cm-p4 cm-border-bottom"
          >
            <span class="cm-p3 font-semibold">{{ n.authorName }}</span>
            •
            <span class="cm-p3">{{ n.date }}</span>

            <p v-if="n.source" class="cm-p3 grey-70">
              <span
                v-if="
                  n.typeEvent.toLowerCase() === EventType.NOTE.toLowerCase()
                "
                >Note added to: match for {{ n.jobPositionName }}
                {{ n.companyName }}</span
              >
              <span v-else>{{ n.source }}</span>
            </p>
            <p class="cm-p3 text-secondary">
              {{ n.content }}
            </p>
          </div>
        </div>

        <div class="cm-side-panel-footer">
          <cm-textarea
            ref="input"
            size="md"
            :appendIcon="{ iconName: 'mdiSend', color: 'grey-70' }"
            :placeholder="`Add note to ${notes?.fullName}...`"
            :value="note"
            :rows="getRows()"
            @cm-change="note = $event.detail"
            @keyup.enter="saveNoteHandler"
          ></cm-textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use 'sass:map';

.container-notes {
  height: inherit;
  padding: 20px;
}

.cm-border-bottom {
  border-bottom: 1px solid map.get($greyscale-palette, 30);
}

.side-panel-container {
  position: absolute;
  width: 100%;
  max-width: 100%;
  background: white;
  height: 100%;
  top: 0;

  @media screen and (min-width: $breakpoint-md) {
    max-width: 375px;
  }

  cm-icon {
    cursor: pointer;
  }

  .cm-side-panel-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;
    padding: 0 16px;
    border-bottom: 2px solid map.get($greyscale-palette, 30);
  }

  .cm-side-panel-content-notes {
    overflow-y: auto;
    height: calc(100% - 128px);
  }

  .cm-side-panel-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 16px;
    background: white;
    box-shadow: 4px 4px 20px rgba(189, 189, 189, 0.25);
  }

  .grey-70 {
    color: map.get($greyscale-palette, 70);
  }

  &.side-panel-right {
    transition: right 0.4s ease;
    right: -100%;
  }

  &.side-panel-left {
    transition: left 0.4s ease;
    left: -300px;
  }
}

.visible {
  .side-panel-right {
    right: 0;
    @media screen and (min-width: $breakpoint-md) {
      right: calc(-100% + 375px);
    }
  }
  .side-panel-left {
    left: 0;
  }
}
</style>
