<script setup lang="ts">
import { DropdownItem } from '@emotioncod/cm-design-system';
import {
  CmBreadcrumbs,
  CmDropdown,
  CmIcon
} from '@emotioncod/cm-design-system-vue';
import ENV from '~/config/env';
import { parseImage } from '~/utils';

const { t } = useI18n();
const store = useBreadbrumb();
const settingStore = useSettings();
const breadcrumbs = computed(() => store.breadcrumbs);

const profileActions = ref<DropdownItem[]>([]);

onMounted(() => {
  profileActions.value = [
    {
      text: t('actions.profile'),
      href: ENV.PROFILE_PAGE_URL,
      active: false,
      disabled: false
    },
    {
      text: t('actions.logout'),
      active: false,
      disabled: false,
      action: () => {
        const authService = useNuxtApp().$authService;

        authService.logout();
      }
    }
  ];
});

watch(
  () => settingStore.settings.user,
  () => {
    if (settingStore.settings.user.profile?.name) {
      profileActions.value = [
        {
          text: settingStore.settings.user?.profile?.name
            ? settingStore.settings.user?.profile?.name +
              ' ' +
              settingStore.settings.user?.profile?.surname
            : '',
          href: ENV.PROFILE_PAGE_URL,
          active: false,
          disabled: true
        },
        ...profileActions.value
      ];
    }
  }
);
</script>

<template>
  <div class="app-bar-container bg-white">
    <div class="md-down">
      <cm-icon
        icon-name="mdiMenu"
        color="secondary"
        size="24"
        @click="
          () =>
            (settingStore.settings.openMenu = !settingStore.settings.openMenu)
        "
      />
    </div>
    <div class="md-up">
      <cm-breadcrumbs :items="breadcrumbs" />
    </div>
    <cm-dropdown :entries="profileActions" side="right">
      <img
        v-if="settingStore.settings.user?.profile?.photo"
        :alt="settingStore.settings.user?.profile?.surname"
        :src="parseImage(settingStore.settings.user?.profile?.photo)"
        width="32"
        height="32"
        class="rounded-circle"
        loading="lazy"
      />
      <cm-icon
        v-if="!settingStore.settings.user?.profile?.photo"
        icon-name="mdiAccountCircle"
        color="secondary"
        size="32"
      ></cm-icon>
    </cm-dropdown>
  </div>
</template>

<style scoped lang="scss">
.app-bar-container {
  height: 64px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: $breakpoint-md) {
    padding: 16px 24px;
  }
}

.rounded-circle {
  border-radius: 100%;
  overflow: hidden;
}
</style>
