<script setup lang="ts">
import {
  CmModalPrompt,
  CmSideNavigation,
  CmSnackbar
} from '@emotioncod/cm-design-system-vue';
import { ActionType } from '@emotioncod/cm-definitions/lib/Matches/const';
import navRoutes from '~/router/navigtionMenu';
import { APP_DESCRIPTION, APP_NAME_TITLE, CONSOLE_MESSAGE } from '~/constants';
import type { CandidateNotesPanel } from '~/types/bo-types';
import { version } from '~/package.json';
import { candidateNoteResolve } from '~/resolvers/candidate';

const store = useSettings();
const router = useRouter();
const { state, dispatch } = useEvents();
const matchesStore = useMatches();
const candidateStore = useCandidates();

const notes = ref<CandidateNotesPanel>({
  fullName: '',
  list: []
});

const messageStyles = [
  'font-size: 12px',
  'font-family: monospace',
  'background: #0E1E30',
  'display: inline-block',
  'color: white',
  'padding: 8px 19px',
  'border: 1px dashed;'
].join(';');

useHead({
  titleTemplate: `%s - ${APP_NAME_TITLE}`,
  meta: [
    {
      name: 'description',
      content: APP_DESCRIPTION
    }
  ],
  bodyAttrs: {},
  script: [
    {
      innerHTML: `console.log('${CONSOLE_MESSAGE} v${version}', '${messageStyles}')`
    }
  ]
});

watch(
  () => state.actionType,
  () => {
    if (
      state.actionType.toLowerCase() === ActionType.OPEN_NOTES.toLowerCase()
    ) {
      notes.value = candidateNoteResolve(
        state.payload.userId,
        candidateStore.candidates,
        candidateStore.candidate,
        state.payload.matchId,
        matchesStore.matches
      );
    }
  }
);

onMounted(async () => {
  // await store.setUser();
});

const closeNote = () => {
  dispatch({
    type: 'ActionType',
    eventType: ActionType.CLOSE_NOTES,
    statusMatch: null,
    payload: {
      userId: '',
      matchId: ''
    }
  });
};

router.beforeEach((to: any, from: any) => {
  /**
   * If change page from pagination, don't close menu
   */
  if (to.query.page) {
    if (to.query.page !== from.query.page) {
      return;
    }
  }

  /**
   * Close Menu panel when route changes
   */
  dispatch({
    type: 'ActionType',
    eventType: ActionType.CLOSE_NOTES,
    statusMatch: null,
    payload: {
      userId: '',
      matchId: ''
    }
  });
});
</script>

<template>
  <div>
    <div class="layout-default-container bg-grey-20">
      <cm-side-navigation
        :routes="
          navRoutes.map((route) => ({
            ...route,
            action: () => {
              store.settings.openMenu = false;
              $router.push(route.route);
            },
            active:
              $route.name === route.id ||
              $route.matched.some((record) => record.name.includes(route.id))
          }))
        "
        :open="store.settings.openMenu"
        @toggled="store.settings.openMenu = $event.detail"
      />
      <section :class="{ shrink: store.settings.openMenu }">
        <header>
          <AppBar />
        </header>
        <main>
          <slot />
        </main>
      </section>
    </div>

    <div class="modal-container">
      <ClientOnly>
        <Teleport to="body">
          <cm-modal-prompt
            :show="store.settings.showModal.show"
            :modal-title="store.settings.showModal.title"
            :modal-text="store.settings.showModal.message"
            position="middle"
            :cta-row="[
              {
                text: store.settings.showModal.cancelLabel,
                ctaType: 'secondary',
                action: () => store.settings.showModal.cancelAction()
              },
              {
                text: store.settings.showModal.confirmLabel,
                ctaType: 'primary',
                action: () => store.settings.showModal.confirmAction()
              }
            ]"
            @closed="() => store.settings.showModal.cancelAction()"
          />
          <cm-snackbar
            v-if="store.settings.snacknar.show"
            :show="store.settings.snacknar.show"
            :text="store.settings.snacknar.message"
            :fade-timeout="store.settings.snacknar.timeout"
            show-close="false"
            @closed="store.settings.snacknar.show = $event.detail"
          />
          <NoteSidePanel
            :candidate-id="state.payload.userId"
            :match-id="state.payload.matchId"
            :visible="store.settings.showSidePanel"
            side="right"
            :hide-backdrop="true"
            :disable-close-button="false"
            :notes="notes"
            @close-note="closeNote"
          />
          <LoadingComponent v-if="store.settings.loading" />
        </Teleport>
      </ClientOnly>
    </div>
  </div>
</template>

<style lang="scss">
.layout-default-container {
  display: flex;
  min-height: 100vh;
  width: 100%;

  nav {
    height: 100%;
  }

  section {
    height: 100%;
    width: 100%;

    main {
      max-height: calc(100vh - 64px);
      overflow-y: auto;
      padding-bottom: 80px;

      .main-content {
        padding: 24px 16px;
      }
    }

    @media screen and (min-width: $breakpoint-md) {
      transition: width 0.3s ease;
      width: calc(100% - 64px);

      &.shrink {
        width: calc(100% - 290px);
      }

      main {
        .main-content {
          padding: 24px;
        }
      }
    }
  }
}

cm-side-navigation {
  cm-tooltip {
    display: block;
  }
}
</style>
